import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Seo from "../components/seo";
import { ContentBg, ContentBox } from "../components/ui";

const About = props => {
  return (
    <ContentBg src="/static/8b3a9a1f7393d7ee37f1d58c1ce3ddd7/af260/krant.jpg">
      <ContentBox>
        <Seo title="About Mark Kater" />
        <h1>Mark WIE?</h1>
        <p>
          Kater. Aangenaam. Geboren en getogen in het mooie Langedijk in Noord
          Holland en sinds 2005 woonachtig in "the Bay Area". Hoe zit dat
          precies??
        </p>
        <p>
          "Mijn verhaal" begon in 2000, toen we met mijn vader en moeder en m'n
          broer Jos op vakantie gingen naar de Amerikaanse westkust. We wilden
          tijdens onze vakantie iets "typisch Amerikaans" doen, zoals naar een
          honkbal wedstrijd gaan. Na in eerste instantie in San Diego een
          wedstrijd te zijn misgelopen, belandden we op onze reis uiteindelijk
          in San Francisco. Het scheen dat ze daar ook een honkbal team hadden,
          de Giants genaamd, dus gingen we daar dan maar eens gaan kijken.
          <br />
          Het was bok-koud, de wedstrijd was zoals ze hier zeggen "boring", maar
          op het allerlaatste moment (met 2 uit in de laatste inning) sloegen de
          Giants een homerun en iedereen in het stadion werd gek!
        </p>
        <p>
          Nu, jaren later, denk ik nog wel eens terug... als die gozer (Armando
          Rios, zal z'n naam nooit vergeten) die bal NIET over het hek had
          geslagen, had mijn leven er heel anders uit gezien... en woonde ik
          hier wellicht helemaal niet!
        </p>
        <p>
          Samen met mijn broer Jos waren we op slag "fan" van de Giants en de 2
          volgende dagen dat we nog in San Francisco waren, gingen we terug naar
          het stadion, om maar zo veel mogelijk "baseball" mee te kunnen pikken,
          voordat we weer terug naar Nederland moesten.
        </p>
        <p>
          Terug in Nederland, bleven we de Giants volgen, via internet, en in
          2001 verbeterde Barry Bonds (van de Giants) het homerun record aller
          tijden. Inmiddels was ik zelf bezig met mijn afstudeerscriptie en werd
          het tijd om na te denken wat ik na mijn studie wilde doen. Eerst maar
          eens wat meer van de wereld zien, leek me wel een goed idee. Na
          maanden van papierwerk, wist ik een "work &amp; travel" visum te
          bemachtigen voor de VS. Tevens had ik vanuit Nederland een baantje
          weten te vinden als verhuizer... in San Francisco! Zwaar werk, dat
          wel, maar leuk als een zomerbaantje om de cultuur te leren kennen en
          te zien hoe mensen hier leven. Ondertussen had ik genoeg tijd over om
          zo veel mogelijk Giants wedstrijden te bezoeken. Dat was in de zomer
          van 2002, de zomer dat de Giants de World Series haalden. Niet
          gewonnen, helaas, maar evengoed een geweldig seizoen. Ter afsluiting
          van mijn halfjaar in de VS, ben ik nog een maand langs zowel de oost-
          als de westkust van Amerika gereisd... kan het iedereen aanraden! 🙂
        </p>
        <p>
          Een ander detail wat ik nog was "vergeten" te vermelden, was dat ik in
          San Francisco ene Michelle had ontmoet. Leuk meisje, Giants fan... eh,
          ja. 🙂 <br />
          Via internet contact met haar gehouden... en in 2003 een vakantie
          geboekt voor een korte terugkeer naar San Francisco. Om de Giants in
          de playoffs te zien spelen... om weer een biertje te drinken met m'n
          vrienden van het verhuisbedrijf... en... om Michelle te zien.
          <br />
          Nou ja goed, zal het verder niet een al te lang verhaal maken... via
          internet praatten we vrijwel iedere dag met elkaar... in mei 2004 heb
          ik haar familie ontmoet... in augustus 2004 kwam ze naar Nederland en
          heeft ze mijn familie ontmoet... in december 2004 ben ik wederom naar
          San Francisco gevlogen en heb ik Michelle in Las Vegas ten huwelijk
          gevraagd. 🙂
        </p>
        <p>
          De maanden daarna zijn we druk bezig geweest om mijn visum in orde te
          krijgen... Michelle kwam in maart weer naar Nederland en in mei ging
          ik weer naar San Francisco... dit keer een enkele reis en in Augustus
          zijn we getrouwd!
        </p>
        <p>
          In 2007 stond bovenstaand verhaal trouwens ook in het Noordholland
          Dagblad, geschreven door Jan Vriend. Zie hier dat artikel, "Homerun
          naar een nieuw leven":
        </p>
        <p>
          <StaticImage src="../photos/about/krant.jpg" alt="Mark in de krant" />
        </p>
        <p>
          Inmiddels zijn we vele jaren verder. We wonen nog steeds in the Bay
          Area, hebben twee hele mooie kinderen en een geweldige hond. Huisje,
          boompje, beestje, maar dan in silicon valley. Door de jaren heen heb
          ik voor heel wat interessante bedrijven gewerkt... als manager bij
          Tagged, een social network met 300 miljoen leden maar wat eigenlijk
          nooit <em>echt</em> populair was. Als programmeur bij wat kleinere
          "startups" en inmiddels alweer een paar jaar als software development
          manager bij <em>eero</em>, wat onderdeel uitmaakt van het grote
          Amazon.
        </p>
        <p>
          Dus nu weet je een beetje WIE Mark Kater is... en dit is mijn website.
          Ik schrijf hier zo nu en dan (niet meer zo vaak als voorheen)
          berichten over mijn belevenissen... vooral voor mezelf, het is leuk om
          later terug te lezen wat me jaren geleden bezig hield... maar mocht je
          het ook boeiend vinden om te lezen hoe het mij hier vergaat, hey... be
          my guest! 🙂
        </p>
        <p>Groeten uit San Francisco!</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </ContentBox>
    </ContentBg>
  );
};

export default About;
